<template>
    <Publication :streamBlocks="streamContained" :tags="combinedTags" :parentPath="parentPath">
        <template #hero>
            <ImageHero :image="heroImage" variant="short"></ImageHero>
        </template>

        <template #head>
            <PublicationSummary
                subtype="Edition"
                :dek="dek"
                :title="title"
                :contributors="contributors"
                :translations="translations"
                :publishedAt="publishedAt"
            >
                <!-- <template #supplementalMeta> Issue #{{}} </template> -->
            </PublicationSummary>
        </template>

        <template #main-left>
            <AsideBlock :hasPadding="false">
                <AffiliationCard
                    :heading="parentAffiliationContent.title"
                    :dek="parentAffiliationContent.dek"
                    headingElement="h3"
                    :image="parentAffiliationContent.image"
                    :url="parentAffiliationContent.path"
                    class="card"
                />
            </AsideBlock>
            <AsideBlock v-if="supplementalLinks?.length" :heading="$t('additional links')">
                <LinkList :links="supplementalLinks?.map((link) => link.link)" class="supplemental-links" />
            </AsideBlock>
        </template>

        <template #main-right>
            <PersonBlock v-for="person in contributors" :key="person.id" :person="person" size="25" />
        </template>
    </Publication>
</template>

<script setup>
const { getCenterTagsBySyndication } = useCenters();

const props = defineProps({
    contributors: {
        type: Array,
        default: () => [],
    },
    publishedAt: {
        type: String,
    },
    dek: {
        type: Object,
    },
    parent: {
        type: Object,
    },
    streamContained: {
        type: Object,
    },
    supplementalLinks: {
        type: Object,
    },
    tags: {
        type: Array,
    },
    title: {
        type: String,
    },
    translations: {
        type: Object,
        default: () => {},
    },
    seo: {
        type: Object,
    },
    syndications: {
        type: Object,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
});

const parentAffiliationContent = computed(() => {
    const parentItem = props.parent?.value;
    return {
        path: parentItem.path,
        dek: parentItem.dek,
        title: parentItem.title,
        image: parentItem.hero?.image,
        logoImage: parentItem?.logo,
    };
});

const heroImage = computed(() => {
    return props.parent?.value?.hero?.image || '';
});

const combinedTags = getCenterTagsBySyndication(props.syndications, props.tags);
const parentPath = parentAffiliationContent.value.path;

const headerAttributesStore = useHeaderAttributesStore();

const GTMCenter = useGTM();
const GTMLang = props.locale;
let GTMType = 'Newsletter Edition';
if (parentAffiliationContent?.value.title) {
    GTMType = 'Newsletter Edition - ' + parentAffiliationContent.value.title;
}

onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: GTMType,
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'white' });
});
</script>

<style lang="scss" scoped>
.hero {
    &__image {
        // height: 60rem;
        // object-fit: cover;
        // width: 100%;
    }
}
</style>
